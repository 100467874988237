import { css } from 'styled-components'

const color = {
  transparent: 'transparent',
  black: '#000',
  white: '#FFF',
  green: '#71E789',
  red: '#FF877C',
  //---
  text: '#21393D',
  // primary: '#77D0DD',
  primary: '#54d2df',
  primaryDark: '#387894',
  bgLight: '#F4FDFF',
  grayDark: '#718183',
  grayLight: '#D0D7D8',
}

const size = {
  xs: 4, //px

  s: 8, //px
  m: 16,
  l: 24,

  xl: 32,
  xxl: 48,
  xxxl: 64,
}

const font = {
  //16,18,21,24,28,36,48
}

const breakpoint = {
  desktop: 1024,
  // desktop: 992,
  // tablet: 768,
  // phone: 576,
}

const media = Object.keys(breakpoint).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoint[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

media.switch = (mobile, desktop) => {
  if (typeof window !== 'undefined' && window.innerWidth >= breakpoint.desktop) {
    return desktop
  }
  return mobile
}

const theme = {
  colors: {
    ...color
  },

  //      0  1  2   3   4   5   6   7   8
  space: [0, 4, 8, 16, 24, 32, 48, 64, 96],
  //          0    1   2   3   4   5   6   7   8   9
  fontSizes: [12, 14, 16, 18, 21, 24, 28, 36, 48, 64],

  //              0    1    2
  fontWeights: [400, 600, 700], //regular, semibold, bold
  breakpoints: [`1024px`], //https://styled-system.com/responsive-styles
  buttons: {
    ghost: {
      color: color.white,
      border: `1px solid ${color.white}`,
      backgroundColor: color.transparent
    },
    ghostDark: {
      color: color.black,
      border: `1px solid ${color.black}`,
      backgroundColor: color.transparent
    },
    primary: {
      color: color.white,
      backgroundColor: color.primary,
      boxShadow: `8px 8px 32px 0px rgba(119,208,221,0.5)`
    },
    secondary: {
      color: color.primary,
      backgroundColor: color.white,
      boxShadow: `8px 8px 32px 0px rgba(119,208,221,0.5)`
    }
  },
  shadows: {
    primary: '8px 8px 32px 0px rgba(119,208,221,0.5)', //#77D0DD 50%
    primaryDark: '8px 8px 32px 0px rgba(33, 57, 61, 0.2)', //#21393D 20%
    black: '8px 8px 32px 0px rgba(208, 215, 216, 0.6)', //#D0D7D8 60%
  }
}

// space.small = theme.space[1]
// space.medium = theme.space[2]
// space.large = theme.space[3]

export default {
  color,
  size,
  font,
  media,
  breakpoint,
  theme,
  // spacing,
};